<template>
  <section id="privacy">
    <div class="content-wrap p-0">
      <div class="container">
        <div class="privacy-container privacy-first-container">
          <h2 class="bar-title">Central da Privacidade</h2>
          <h3>
            Nós levamos sua privacidade a sério
          </h3>
          <p>
            Nesta página, você encontra a descrição dos nossos procedimentos internos e os tipos de informações
            coletadas. Além disso, disponibilizamos a nossa Política de privacidade e proteção de dados na íntegra para
            consulta e o <router-link to="/direitos-de-privacidade" class="privacy-therms-link">Formulário de direitos
              de privacidade</router-link> para que você
            possa exercer o seus direitos de titular de
            dados.
          </p>
          <div class="btn-content">
            <a class="button button-rounded nott button-circle"
              href="https://superpro-questions-images.s3.us-east-2.amazonaws.com/privacidade/Politica_de_Privacidade_PAZZEI..pdf"
              target="_blank">Política de Privacidade na íntegra
            </a>
            <router-link to="/direitos-de-privacidade" class="button button-rounded nott button-circle">Formulário de
              direitos de privacidade
            </router-link>
          </div>
        </div>

        <h3>
          Política de privacidade e proteção de dados
        </h3>
        <heading-with-paragraph v-for="item in paragraphs" :key="item.paragraphArray"
          :paragraph-heading="item.paragraphHeading" :paragraph-array="item.paragraphArray" />

        <div class="privacy-container privacy-second-container">
          <h4>
            Quer saber mais?
          </h4>
          <span>
            Acesse a Política de Privacidade e Proteção de Dados na íntegra
          </span>
          <div class="btn-content">
            <a class="button button-rounded nott button-circle"
              href="https://superpro-questions-images.s3.us-east-2.amazonaws.com/privacidade/Politica_de_Privacidade_PAZZEI..pdf"
              target="_blank">Acessar Política de Privacidade
            </a>
          </div>
        </div>

        <div class="container clearfix">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="faq-container">
                <h2 class="bar-title">Perguntas Frequentes</h2>
              </div>

              <div class="accordion accordion-flush" :id="accordionId">
                <accordion-item v-for="item in accordion" :key="item.buttonText" :button-text="item.buttonText"
                  :accordion-body="item.accordionBody" :order="item.order" :accordion-id="accordionId" />
              </div>
            </div>
          </div>
        </div>

        <div class="privacy-container privacy-third-container">
          <h2 class="bar-title">Direitos de Privacidade</h2>
          <h3>
            Exercendo seu direito de privacidade
          </h3>
          <p>
            Nos termos do art. 18 da LGPD, você pode requerer informações e correções de seus dados, conforme nossa
            <a class="privacy-therms-link"
              href="https://superpro-questions-images.s3.us-east-2.amazonaws.com/privacidade/Politica_de_Privacidade_PAZZEI..pdf">Política
              de Privacidade</a>. Para requerer o atendimento ao seu direito, preencha o formulário.
          </p>
          <div class="btn-content">
            <a class="button button-rounded nott button-circle"
              href="https://superpro-questions-images.s3.us-east-2.amazonaws.com/privacidade/Politica_de_Privacidade_PAZZEI..pdf"
              target="_blank">Política de Privacidade na íntegra
            </a>
            <router-link to="/direitos-de-privacidade" class="button button-rounded nott button-circle">Formulário de
              direitos de privacidade
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AccordionItem from '@/components/util/AccordionItem.vue';
import HeadingWithParagraph from '@/components/util/HeadingWithParagraph.vue';

export default {
  name: 'PrivacyView',
  data() {
    return {
      paragraphs: [
        { paragraphHeading: null, paragraphArray: ["Primeiramente, esclarecemos que você pode ter acesso ao teor completo de nossa Política <a class='privacy-therms-link' href='https://superpro-questions-images.s3.us-east-2.amazonaws.com/privacidade/Politica_de_Privacidade_PAZZEI..pdf' target='_blank'>clicando aqui</a>. Em resumo, gostaríamos de apresentar como cuidamos dos seus dados da seguinte forma:"] },
        { paragraphHeading: "Finalidades específicas no uso de dados pessoais", paragraphArray: ["Nosso objetivo é garantir que, nos termos da Lei 13.709/2018  (Lei Geral de Proteção de Dados), o uso de seus dados pessoais seja feito com a maior <strong>transparência</strong> possível. Para isso, descrevemos todos os nossos procedimentos internos e os tipos de informações que precisamos para lhe prestar o melhor serviço dentro das soluções que oferecemos."] },
        { paragraphHeading: "Como utilizamos os dados pessoais", paragraphArray: ["Em razão do tipo de negócio e serviços que prestamos, atuamos tanto como operadores quanto controladores de dados, ou seja, por vezes operamos o banco de dados do nosso cliente, por vezes coletamos a informação do cliente e a utilizamos dentro do nosso próprio processo produtivo. Com relação a nossos colaboradores e prestadores de serviços, utilizamos os dados exclusivamente para cumprimento de obrigações legais e de contrato."] },
        { paragraphHeading: "Dados de navegação", paragraphArray: ["Aos leitores cadastrados em nosso blog e newsletter, informamos que você poderá optar por salvar seu nome, e-mail e site nos cookies. Isso visa seu conforto, assim você não precisará preencher seus dados novamente quando nos visitar novamente. Estes cookies duram um ano. Se você tem uma conta e acessa este site, um cookie temporário será criado para determinar se seu navegador aceita cookies. Ele não contém nenhum dado pessoal e será descartado quando você fechar seu navegador. Quando você acessa sua conta no site, também criamos cookies específicos para salvar os dados da sua conta e suas escolhas de exibição de tela. Cookies de login são mantidos por certo período e posteriormente descartados automaticamente. Se você se desconectar da sua conta, os cookies de login serão removidos."] },
        { paragraphHeading: null, paragraphArray: ["<strong>Mídia incorporada de outros sites:</strong> Artigos neste site podem incluir conteúdo incorporado como, por exemplo, vídeos, imagens, artigos, etc. Conteúdos incorporados de outros sites se comportam exatamente da mesma forma como se o visitante estivesse visitando o outro site. Estes sites podem coletar dados sobre você, usar cookies, incorporar rastreamento adicional de terceiros e monitorar sua interação com este conteúdo incorporado, incluindo sua interação com o conteúdo incorporado se você tem uma conta e está conectado com o site."] },
        { paragraphHeading: "Dados de clientes, colaboradores e prestadores de serviço", paragraphArray: ["Os dados que compartilhamos são de clientes, prestadores de serviço (que são pessoas jurídicas) e colaboradores. Em suma, eles são compartilhados para fins de cumprimento de obrigações fiscais e tributárias, sendo, portanto, repassados a Receita Estadual, Receita Federal, INSS e Fazenda Municipal, a depender do vínculo.<br>No desenvolvimento de suas atividades, a Super Professor® se utiliza de softwares de apoio, sendo os dados também compartilhados com os desenvolvedores destes sistemas. Estes possuem obrigação contratual de manutenção de nível satisfatório de segurança e sigilo, bem como se responsabilizam no caso de falha e exploração de vulnerabilidade em seu sistema."] },
        { paragraphHeading: "Quanto tempo os dados pessoais são armazenados", paragraphArray: ["Os dados pessoais são armazenados durante a vigência de todos os contratos e pelo prazo que a Lei determina a responsabilidade fiscal e tributária da Super Professor®. Após este período, os dados e informações são excluídas mediante requerimento específico do titular neste sentido. Você pode solicitar a exclusão de seus dados <a class='privacy-therms-link' href='/direitos-de-privacidade'>aqui</a>. Sua solicitação será analisada pelo responsável e, caso não tenhamos mais a obrigação de guardar a informação, seus dados serão anonimizados."] },
        { paragraphHeading: "Encarregado de dados", paragraphArray: ["A Super Professor® possui Encarregado de Dados, o qual pode ser contactado pela Agência Nacional de Proteção de Dados e pelos Titulares através dos seguintes canais:<br>Fernando Rangel<br>E-mail: dpo@superprofessor.com.br"] },
        { paragraphHeading: "Segurança da informação", paragraphArray: ["A nível de segurança, contamos com ferramentas de Segurança da Informação consistentes em aplicações de Firewall e Antivírus. Utilizamos ferramentas licenciadas de prestadores de serviço que também garantem a segurança de seus servidores. O acesso a pastas de servidor e funcionalidades de sistemas utilizados é restrito de acordo com a função exercida por cada colaborador e/ou prestador de serviço. Além disso, os colaboradores e prestadores de serviço terceirizados são treinados em boas práticas de segurança da informação e assumem a responsabilidade pela violação da privacidade de eventual informação mal utilizada."] },
      ],
      accordionId: "privacyFaqAccordion",
      accordion: [
        { buttonText: "O que é LGPD?", accordionBody: "A Lei Geral de Proteção de Dados (LGPD) é uma lei brasileira que estabelece regras para o tratamento de dados pessoais por pessoas físicas ou jurídicas, públicas ou privadas, em meios físicos ou digitais. A LGPD tem como objetivo proteger os direitos fundamentais de liberdade e de privacidade com relação à coleta, armazenamento, uso, processamento e compartilhamento de seus dados pessoais.", order: "One" },
        { buttonText: "O que são dados pessoais? E o que é o tratamento de dados?", accordionBody: "Dados pessoais são todas as informações relacionadas a pessoa natural identificada ou identificável, tais como: nome, sobrenome, e-mail, data de nascimento, entre outros. O tratamento de dados se refere a toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. No geral, o tratamento de dados é feito para cumprir com obrigações legais e contratuais da empresa e garantir a operação dos serviços prestados. Os dados pessoais coletados e tratados pela Super Professor estão mapeados e descritos na <a class='privacy-therms-link' href='https://superpro-questions-images.s3.us-east-2.amazonaws.com/privacidade/Politica_de_Privacidade_PAZZEI..pdf'>Política de Privacidade e Proteção de Dados</a>, disponível para consulta.", order: "Two" },
        { buttonText: "Quem é o titular de dados?", accordionBody: "O titular dos dados é a pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.", order: "Three" },
        { buttonText: "Quais são meus direitos como titular?", accordionBody: "Como titular dos dados pessoais tratados, você tem direito a: confirmação da existência de tratamento; acesso aos dados; correção de dados incompletos, inexatos ou desatualizados; anonimização, bloqueio ou eliminação de dados; portabilidade dos dados; informação sobre o compartilhamento de dados. Para exercer seus direitos, basta preencher o <a class='privacy-therms-link' href='/direitos-de-privacidade'></a>formulário de direitos de privacidade o formulário de direitos de privacidade e requerer o atendimento.", order: "Four" },
        { buttonText: "Quem é o DPO? O que ele faz?", accordionBody: "DPO (Data Protection Officer), ou Encarregado de dados, é pessoa indicada pelo controlador e/ou operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD). Ele é o responsável por receber e responder às solicitações dos titulares dos dados, garantindo que a empresa esteja em conformidade com a LGPD, a lei brasileira que regula o tratamento de dados pessoais.", order: "Five" },
      ]
    }
  },
  components: {
    AccordionItem,
    HeadingWithParagraph,
  },
}
</script>

<style scoped>
#privacy {
  padding: 80px 0;
}

.privacy-first-container {
  padding-bottom: 80px;
}

h3 {
  font-weight: 700;
  text-align: center;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.btn-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 22px;
}

.button {
  margin: 0;
}

.privacy-second-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 80px auto;
}

.privacy-second-container * {
  margin: 0;
}

.privacy-second-container .button {
  margin-top: 10px;
}

.privacy-second-container span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.privacy-third-container {
  padding-top: 80px;
}

@media (max-width: 670px) {
  p {
    text-align: justify;
  }

  .privacy-container span {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: calc(var(--bs-gutter-x)* 0.25);
    padding-left: calc(var(--bs-gutter-x)* 0.25);
  }

  h3 {
    line-height: 42px;
    font-size: 28px;
  }
}
</style>
