<template>
  <footer id="footer" class="border-0 bg-color-primary">
    <div class="container">
      <div class="footer-widgets-wrap pb-5 clearfix">
        <div class="row col-mb-30 center">
          <div class="col-12 logo-col">
            <img class="footer-logo" src="../assets/logo.png" alt="Logo Pazzei" />
          </div>
          <div class="col-12">
            <div
              class="copyrights-menu copyright-links clearfix ls1 one-page-menu"
              data-easing="easeInOutExpo"
              data-speed="1250"
              data-offset="75"
            >
              <a
                class="link link-1 text-color-purple"
                href="#"
                data-href="#diferenciais"
                >Diferenciais</a
              >
              <a
                class="link link-1 text-color-purple"
                href="#"
                data-href="#beneficios"
                >Benefícios</a
              >
              <a
                class="link link-1 text-color-purple"
                href="#"
                data-href="#planos"
                >Planos</a
              >
              <a
                class="link link-1 text-color-purple"
                href="#"
                data-bs-toggle="modal"
                data-bs-target=".bs-modal-rd-station"
                >Contato</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="social-media" class="social-media">
      <a href="https://www.instagram.com/pazzei.digital/" target="_blank" rel="noopener noreferrer">
        <img src="../assets/icons/social/instagram.svg" alt="instagram">
      </a>
      <a href="https://www.facebook.com/pazzeidigital/" target="_blank" rel="noopener noreferrer">
        <img src="../assets/icons/social/facebook.svg" alt="facebook">
      </a>
      <a href="https://www.youtube.com/@pazzei" target="_blank" rel="noopener noreferrer">
        <img src="../assets/icons/social/youtube.svg" alt="youtube">
      </a>
      <a href="https://www.tiktok.com/@pazzeidigital" target="_blank" rel="noopener noreferrer">
        <img src="../assets/icons/social/tiktok.svg" alt="tiktok">
      </a>
      <a href="https://www.linkedin.com/company/pazzei/" target="_blank" rel="noopener noreferrer">
        <img src="../assets/icons/social/linkedin.svg" alt="linkedin">
      </a>
    </div>

    <!-- Copyrights
		============================================= -->
    <div id="copyrights" class="bg-color-primary">
      <div class="container clearfix">
        <div class="row justify-content-between">
          <div
            class="col-12 col-lg-auto text-center text-lg-start text-color-purple ls1 copyrights-menu copyright-links mb-0"
          >
            &copy; Pazzei {{ currYear }} Todos os direitos resevados.
            <br />
            <router-link
              to="/politicas-de-privacidade"
              class="link link-1 text-color-purple m-0"
            >Política de privacidade
            </router-link>
            |
            <router-link
              to="/termos-de-uso"
              class="link link-1 text-color-purple m-0"
              >Termos de uso
            </router-link>
          </div>
          <div class="col-12 col-lg-auto text-center text-lg-end">
            <div
              class="copyrights-menu copyright-links clearfix text-color-purple ls1"
            >
              Desenvolvido por
              <br />
              <a
                class="link link-1 text-color-purple m-0"
                href="https://www.viptech.com.br/"
                target="_blank"
                >Viptech - Smart Solutions</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #copyrights end -->
  </footer>
  <div class="box-cookies hide">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-8">
          <p class="msg-cookies mb-0">
            Utilizamos cookies para proporcionar a melhor experiência em nosso
            site. Clicando em "Aceitar Cookies", você concorda com o
            armazenamento de cookies em seu dispositivo.
          </p>
        </div>
        <div class="col-12 col-md-4">
          <button
            class="btn-cookies my-2 button button-rounded button-large nott button-circle"
          >
            <span>Aceitar Cookies</span> <i class="ms-2 icon-line-check"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bs-modal-termos"
    tabindex="-1"
    role="dialog"
    aria-labelledby="centerModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close btn-sm"
            data-bs-dismiss="modal"
            aria-hidden="true"
          ></button>
        </div>
        <div class="modal-body">
          <div style="text-align: justify" v-html="termos"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bs-modal-termos"
    tabindex="-1"
    role="dialog"
    aria-labelledby="centerModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close btn-sm"
            data-bs-dismiss="modal"
            aria-hidden="true"
          ></button>
        </div>
        <div class="modal-body">
          <div style="text-align: justify" v-html="termos"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade bs-modal-politica"
    tabindex="-1"
    role="dialog"
    aria-labelledby="centerModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close btn-sm"
            data-bs-dismiss="modal"
            aria-hidden="true"
          ></button>
        </div>
        <div class="modal-body">
          <div style="text-align: justify" v-html="politica"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FooterComponent",
  data() {
    return {
      res: null,
      currYear: new Date().getFullYear(),
      termos: null,
      politica: null,
    };
  },
  mounted() {
    const termsURL = { termsOfUse: "", privacyPolicy: "" };

    axios
      .get(
        "https://staging-pazzei-gateway.pazzei.com.br/users/terms/last-terms"
      )
      .then((response) => {
        this.res = response.data.data;
        this.res.forEach((term) => {
          if (term.termType.type === "terms-of-use") {
            termsURL.termsOfUse =
              process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL +
              "terms-of-use/" +
              term.id +
              ".html";
          } else {
            termsURL.privacyPolicy =
              process.env.VUE_APP_AWS_STATIC_FILES_BASE_URL +
              "privacy-policy/" +
              term.id +
              ".html";
          }
        });

        axios
          .get(termsURL.termsOfUse)
          .then((response) => (this.termos = response.data));
        axios
          .get(termsURL.privacyPolicy)
          .then((response) => (this.politica = response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

$(function () {
  "use strict";
  (() => {
    if (!localStorage.pureJavaScriptCookies) {
      document.querySelector(".box-cookies").classList.remove("hide");
    }
    const acceptCookies = () => {
      document.querySelector(".box-cookies").classList.add("hide");
      localStorage.setItem("pureJavaScriptCookies", "accept");
    };
    const btnCookies = document.querySelector(".btn-cookies");
    btnCookies.addEventListener("click", acceptCookies);
  })();
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box-cookies.hide {
  display: none !important;
}

.box-cookies {
  font-weight: 100;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 998;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-cookies p {
  margin-bottom: 0px;
}

.box-cookies .msg-cookies,
.box-cookies {
  text-align: center;
  padding: 10px;
  color: #fff;
  font-size: 15px;
}

.box-cookies .btn-cookies,
.btn-politica {
  cursor: pointer;
  align-self: normal;
}
@media screen and (max-width: 600px) {
  .box-cookies {
    flex-direction: column;
  }
  .btn-politica {
    margin-bottom: 10px;
  }
}

#footer * {
  color: var(--white) !important;
  font: 'public-sans';
  font-weight: 400;
}

.footer-widgets-wrap {
  padding-top: 69px !important;
}

.logo-col {
  display: flex;
  justify-content: center;
}

.footer-logo {
  width: 244px;
  height: 58px;
}

.social-media {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.social-media > a > img {
  width: 24px;
  height: 24px;
}
</style>
